import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function Tanksgraphtesting() {
  const prices = [10, 25, 20, 26, 30, 32, 36, 38];
  const weights = [
    "3kg",
    "5kg",
    "8kg",
    "10kg",
    "12kg",
    "14kg",
    "16kg",
    "18kg",
    "20kg",
  ];

  const [chartState] = useState({
    series: [
      {
        name: "Tanks",
        data: prices,
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false, // Hide the hamburger menu
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        colors: ["#7858F4"], // Line color
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 1,
          gradientToColors: ["rgba(60, 0, 231, 0.00)"], // End color of the gradient
          inverseColors: false,
          opacityFrom: 0.15,
          opacityTo: 80,
          stops: [20, 100],
          colorStops: [
            {
              offset: 0,
              color: "rgba(88, 0, 231, 0.15)",
              opacity: 1,
            },
            {
              offset: 100,
              color: "rgba(60, 0, 231, 0.00)",
              opacity: 1,
            },
          ],
        },
      },

      xaxis: {
        categories: weights,

        labels: {
          style: {
            colors: "#B4B6C2",
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
          },
        },
        axisBorder: {
          color: "#131433", // X-axis border color
        },
        axisTicks: {
          color: "#131433", // X-axis ticks color
        },
      },
      yaxis: {
        opposite: false, // Ensure y-axis data is on the left
        labels: {
          style: {
            colors: "#B4B6C2",
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
          },
          formatter: function (value) {
            return value; // Adding a dollar sign to y-axis labels
          },
        },
        axisBorder: {
          color: "#7858F4", // Y-axis border color
        },
        axisTicks: {
          color: "#7858F4", // Y-axis ticks color
        },
      },
      grid: {
        borderColor: "#131433", // Grid border color
      },
      legend: {
        horizontalAlign: "left",
        labels: {
          colors: "#B4B6C2", // Legend labels color
        },
      },
    },
  });

  return (
    <div className=" numbertankscrd py-[33px] px-[30px] h-full  ">
      <div className="grid grid-cols-12 gap-3 sm:gap-6     ">
        <div className=" col-span-6 ">
          <h3 className=" f-f-sm-p text-grey text-3xl ">Number of Sales</h3>
        </div>
      </div>
      <div className=" items-center ">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="area"
          height={350}
        />
      </div>
      <div className=" text-center mt-[31px] ">
        <ul className=" inline-flex space-x-2 items-center text-grey f-f-r-p text-sm ">
          <li>
            <div className=" bg-primary-light rounded-[2px] w-[13px] h-[13px] "></div>
          </li>
          <li>Number of sales per tank size</li>
        </ul>
      </div>
    </div>
  );
}
