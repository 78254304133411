import { useCallback, useState } from "react";

const baseUrl = "https://testapp.linksensi.com";

const useAuth = () => {
  const [auth, setAuth] = useState({
    accessToken: null,
    accountType: null,
  });

  const signIn = async (email, password, accountType) => {
    try {
      const response = await fetch(`${baseUrl}/suppliers/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.msg);
      }

      const data = await response.json();
      const { accessToken } = data;

      setAuth({ accessToken, accountType });
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("accountType", accountType);
    } catch (error) {
      throw new Error(error);
    }
  };

  const setAuthState = useCallback((accessToken, accountType) => {
    setAuth({ accessToken, accountType });
  }, []);

  return { auth, signIn, setAuthState };
};

const useSignOut = () => {
  const signOut = useCallback(async () => {
    try {
      const response = await fetch(`${baseUrl}/suppliers/auth/signout`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensure cookies are sent
      });

      if (!response.ok) {
        throw new Error("Failed to sign out");
      }

      // Clear local storage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("accountType");

      window.location.href = "/";
    } catch (error) {
      console.error("Sign out error:", error);
      throw error;
    }
  }, []);

  return signOut;
};

const requestAccount = async (email, accountType) => {
  try {
    const response = await fetch(`${baseUrl}/suppliers/auth/request-account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, accountType }),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.msg);
    }
  } catch (error) {
    throw error;
  }
};

export { useAuth, useSignOut, requestAccount };
