import React from "react";
import Group from "../../assets/images/dollorsign.svg";
import Tanks from "../../assets/images/ww21.svg";
import Active from "../../assets/images/Group 2333a.svg";
import { useSupplierSales } from "../../api";

export default function Card() {
  const { sales } = useSupplierSales("2024-06-12 11:46");
  console.log(sales);

  return (
    <div>
      <div className="grid grid-cols-12 gap-3 sm:gap-6     ">
        <div className=" col-span-12 sm:col-span-6 lg:col-span-4">
          <div className=" crd1 py-[22px] px-[20px] ">
            {/* inner grid started  */}
            <div className="grid grid-cols-12    items-center ">
              <div className=" col-span-7">
                <h2 className=" text-grey f-f-sm-p text-6xl  ">$5700</h2>
                <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                  All time sales
                </h3>
              </div>
              <div className=" col-span-5">
                <img
                  src={Group}
                  className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                />
              </div>
            </div>
            {/* inner grid ended */}
          </div>
        </div>
        <div className=" col-span-12 sm:col-span-6 lg:col-span-4">
          <div className=" crd2 py-[22px] px-[20px] ">
            {/* inner grid started  */}
            <div className="grid grid-cols-12    items-center ">
              <div className=" col-span-7">
                <h2 className=" text-grey f-f-sm-p text-6xl  ">$200</h2>
                <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                  Last month sales
                </h3>
              </div>
              <div className=" col-span-5">
                <img
                  src={Tanks}
                  className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                />
              </div>
            </div>
            {/* inner grid ended */}
          </div>
        </div>
        <div className=" col-span-12 sm:col-span-6 lg:col-span-4">
          <div className=" crd3 py-[22px] px-[20px] ">
            {/* inner grid started  */}
            <div className="grid grid-cols-12    items-center ">
              <div className=" col-span-7">
                <h2 className=" text-grey f-f-sm-p text-6xl  ">$300</h2>
                <select className=" rounded-[8px] outline-none w-[88px] h-[24px] bg-transparent border border-grey-dark text-grey-light f-f-r-r text-tiny  ">
                  <option className=" text-primary " value="0">
                    Feb 2023
                  </option>
                  <option className=" text-primary " value="1">
                    Feb 2023
                  </option>
                  <option className=" text-primary " value="2">
                    Feb 2023
                  </option>
                  <option className=" text-primary " value="3">
                    Feb 2023
                  </option>
                </select>
              </div>
              <div className=" col-span-5">
                <img
                  src={Active}
                  className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                />
              </div>
            </div>
            {/* inner grid ended */}
          </div>
        </div>
      </div>
    </div>
  );
}
